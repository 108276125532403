/* .about {    background: center center no-repeat;    background-size: cover;    padding: 60px 0;    position: relative;  } */
  
  .about::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 9;
  }
  
  .about .container {
    position: relative;
    z-index: 10;
  }
  
  .about .content {
    padding: 30px 30px 30px 0;
  }
  
  .about .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #2f4d5a;
    margin-bottom: 30px;
  }
  
  .about .content p {
    margin-bottom: 30px;
  }
  
  .about .content .about-btn {
    display: inline-block;
    background: #67b0d1;
    padding: 6px 44px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: 0.3s;
    position: relative;
  }
  
  .about .content .about-btn i {
    font-size: 18px;
    position: absolute;
    right: 18px;
    top: 9px;
  }
  
  .about .content .about-btn:hover {
    background: #7bbad7;
  }
  
  .about .icon-boxes .icon-box {
    margin-top: 30px;
  }
  
  .about .icon-boxes .icon-box .promptIcon {
    font-size: 40px;
    color: #67b0d1;
    margin-bottom: 10px;
  }
  
  .about .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  
  .about .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
  }
  
  @media (max-width: 1200px) {
    .about .content {
      padding-right: 0;
    }
  }
  
  @media (max-width: 768px) {
    .about {
      text-align: center;
    }
  }