.wrap {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name {
    font-weight: bold;
}

.statusIcon {
    position: absolute;
    right: 0;
}
