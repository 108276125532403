.portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 35px auto;
    list-style: none;
    text-align: center;
    background: #ecf5f9;
    border-radius: 50px;
    padding: 2px 15px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px 8px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #2f4d5a;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #67b0d1;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  
  .portfolio .portfolio-wrap .portfolio-links {
    opacity: 1;
    left: 0;
    right: 0;
    bottom: -60px;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
    display: flex;
    justify-content: center;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    font-size: 28px;
    text-align: center;
    background: rgba(103, 176, 209, 0.75);
    transition: 0.3s;
    width: 50%;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    background: rgba(103, 176, 209, 0.95);
  }
  
  .portfolio .portfolio-wrap .portfolio-links a+a {
    border-left: 1px solid #8ec4dd;
  }
  
  .portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-links {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #67b0d1;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #67b0d1;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(47, 77, 90, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }

/* CUSTOM CARDS */
  .custom-card {
    background-color: #f4f9fc; /* Use the desired shade of grey */
    border: 2px none;   /* Add a border for better visibility */
    border-radius: 8px;       /* Optional: Add border-radius for rounded corners */
    margin: 7px auto;          /* Center the card horizontally with auto margin */
    text-align: center;           /* Optional: Add some margin for spacing */
  }
  
  /* Optionally, you can style the Card.Header, Card.Title, and Card.Text within the custom card */
  .custom-card .card-header {
    background-color: #f4f9fc;   /* Background color for the header */
    backdrop-filter: blur(5px);
  }
  
  .custom-card .card-title {
    color: #333;              /* Text color for the title */
  }
  
  .custom-card .card-text {
    color: #555;    
    font-weight: 1000;          /* Text color for the text */
  }

  @media (max-width: 768px) {
    .custom-card {
      text-align: center;
    }
  }
  