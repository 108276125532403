.pageWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.userTableWrap {
    overflow: auto;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}
