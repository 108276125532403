.pageWrap {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content horizontally */
    gap: 15px;
}

.dataVisualizationWrap {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    overflow: auto;
}

.title {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.dataTablesWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    overflow: auto;
}
