.form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recapWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recapDetailsWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recapDetails {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row {
    display: flex;
    gap: 10px;
}

.wrap {
    padding: 10px;
    height: 100%;
    width: 100%;
}

.calendarType-race {
    background-color: lightcoral;
}

.calendarType-recap {
    background-color: lightcyan;
    color: black;
}

.row {
    position: relative;
}

.removeButton {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
}

.addButton {
    margin-top: 10px;
    width: 80px;
}
