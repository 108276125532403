.team .member {
    text-align: center;
    margin-bottom: 80px;
    position: relative;
  }
  
  .team .member .pic {
    overflow: hidden;
  }
  
  .team .member .member-info {
    position: absolute;
    top: 85%;
    left: 20px;
    right: 20px;
    background: #fff;
    padding: 20px 0;
    color: #2f4d5a;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    max-height: 95px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  
  .team .member:hover .member-info {
    max-height: 300px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
    color: #2f4d5a;
    position: relative;
    padding-bottom: 10px;
  }
  
  .team .member h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #b1cbd7;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .team .member span {
    font-style: italic;
    display: block;
    justify-content: space-between;
    font-size: 13px;
  }
  
  .team .member .social {
    margin-top: 15px;
    
}
  
  .team .member .social a {
    flex-grow: 14;
    transition: color 0.5s;
    color: #52869d;
  }

  .bi{
    margin-left: 5px;
  }
  
  .team .member .social a:hover {
    color: #67b0d1;
  }
  
  .team .member .social i {
    font-size: 16px;
    margin: 0 2px;
  }
  
  @media (max-width: 992px) {
    .team .member {
      margin-bottom: 110px;
    }
  }