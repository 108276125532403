.navigation {
    width: 11em;
    /* phone */
    /* width: -webkit-fill-available; */
    padding: 0.75rem;
    margin: 20px;
    margin-right: 0;
    /* padding-top: 70px; */
    background-color: rgba(255, 255, 255, 1);
    transition: width 350ms ease 0s;
    border-right: 1px solid rgb(212, 216, 221);
    overflow-x: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 8px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.navigationButtons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.navigationContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.navigationButton {
    border: 0;
    background-color: transparent;
    gap: 10px;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.25rem;
    border-radius: 0.2rem;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(124, 119, 136);
    text-align: left;
}

.navigationButton svg {
    flex-shrink: 0;
}

.navigationButton:hover {
    background: rgb(234, 236, 237);
}

.navigationButton.active {
    background-color: rgb(219, 228, 243);
}

.navigationBottom {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.isCollapsed {
    width: 3.5em;
}

.burger {
    cursor: pointer;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    margin-bottom: 2rem;
}

/* .isCollapsed .burger {
    justify-content: space-around;
} */

.burger svg {
    color: rgb(124, 119, 136);
    transition: all 350ms ease 0s;
}

.isCollapsed .burger svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.logoWrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo {
    width: 40%;
    transition: all 650ms ease 0s;
}

.logoText {
    width: 100%;
    flex-shrink: 0;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 575px) {
    .navigation {
        width: 10em;
        margin: 0;
        border-radius: 0;
    }
    .navigation.isCollapsed {
        min-width: auto;
        width: 3.5em;
        flex-shrink: 0;
    }

    .logoWrap {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}
