.pageWrapMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.pageWrap {
    display: flex;
    width: 100%;
    gap: 10px;
}

.dataCollectionWrap {
    width: 50%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
}

.dataVisualizationWrap {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    overflow: auto;
}

.dataVisualizationWrapMain {
    width:100%;
    align-self: flex-start;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-shrink: 0;
    overflow: auto;
    align-items: center;
    justify-content: space-around;
}

.cssContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 5px;
    font-weight: bold;
}

.velocityContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    font-weight: bold;
}

.velocityContainerMain{
    /* width: 2%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 15px;
}

.velocityContainer input{
    width: 20%;
}

.velocityContainerMain input{
    width: 20%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 768px) {
    .dataCollectionWrap {
        width: 100%;
        border-right: 0;
    }

    .dataVisualizationWrap {
        width: 100%;
        border-right: 0;
        padding: 0;
    }

    .dataVisualizationWrapMain {
        width: 100%;
        border-right: 0;
        padding: 0;
        flex-direction: column;
    }

    .pageWrap {
        flex-direction: column;
    }
}
