#hero {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(55, 142, 181, 0.5), rgba(55, 142, 181, 0.2)), top center;
    background-size: cover;
    position: relative;
    /* margin-bottom: -90px; */
  }
  
  #hero .hero-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: -1px 0 2px #2f4d5a;
  }
  
  #hero h2 {
    color: #fff;
    margin-bottom: 50px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: -1px 0 2px #2f4d5a;
    
  }
  
  #hero h2 .typed{
    color:#2f4d5a;
  }
  
  #hero .btn-get-started {
    font-size: 36px;
    display: inline-block;
    padding: 4px 0;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin: 10px;
    width: 64px;
    height: 64px;
    text-align: center;
    border: 2px solid #fff;
    color: #fff;
  }
  
  #hero .btn-get-started:hover {
    padding-top: 8px;
    background: rgba(255, 255, 255, 0.15);
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }