.linksWrap {
    width: 99%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 30px;
}

.link {
    padding: 5px 20px 5px 20px;
    border-bottom: 1px solid transparent;
    color: var(--primary-color);
}

.linkActive {
    border-bottom: 1px solid var(--primary-color);
}

.content {
    width: 99%;
    height: 100%;
}

@media (max-width: 768px) {
    .link {
        padding: 5px 5px 5px 5px;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
    }
}
