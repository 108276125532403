.wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.coachesWrap {
    padding-right: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px; /* Adjust the gap according to your design preferences */
}

.coachesPreview {
    width: 100%;
    min-width: 250px;
    padding: 10px;
    cursor: pointer;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}

.coachesModalWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 768px) {
    .wrap {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
