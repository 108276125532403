.feedbackWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.feedback {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.feedbackTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feedbackStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
}
