.pageWrap {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content horizontally */
    gap: 20px;
}

.dataVisualizationWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 20px;
}

.graph {
    padding: 10px;
    width: 100%;
}

.title {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.dataTablesWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
    overflow: auto;
}

/* Media queries for smaller screens */
@media screen and (max-width: 768px) {
    .pageWrap {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .dataVisualizationWrap {
        width: 100%;
        height: 100%;
        flex-direction: column; /* Stack charts vertically on smaller screens */
        align-items: center;
    }

    .dataTablesWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: stretch;
        overflow: auto;
    }
}
