.services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
  }
  
  .services .icon-box::before {
    content: "";
    position: absolute;
    background: white;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1;
  }
  
  .services .icon-box:hover::before {
    background: #67b0d1;
    top: 0;
    border-radius: 0px;
  }
  
  .services .promptIcon svg {
    margin-bottom: 15px;
  }
  
  .services .promptIcon svg {
    font-size: 48px;
    line-height: 1;
    color: #67b0d1;
    transition: all 0.3s ease-in-out;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #111;
  }
  
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover .title a,
  .services .icon-box:hover .description {
    color: #fff;
  }
  
  .services .icon-box:hover .icon i {
    color: #fff;
  }