/* Template Body CSS */

:root {
    --base-color: #45a7f7;
    --base-color2: #44a4f4;
    --primary-color: rgb(38, 63, 73);
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #444444;
}

a {
    text-decoration: none;
    color: #67b0d1;
}

a:hover {
    color: #8ec4dd;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
}

#main {
    margin-top: 90px;
}

/* Sections  */
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #f4f9fc;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    position: relative;
    margin-bottom: 30px;
    color: #2f4d5a;
    z-index: 2;
}

.section-title h2::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 122px;
    height: 66px;
    background: url(./section-title-bg.png) no-repeat;
    z-index: -1;
}

.section-title p {
    margin-bottom: 0;
}

.form-row {
    display: flex;
    width: 100%;
    gap: 10px;
}

.form-group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-control {
    border: 0;
    border-bottom: 1px;
    background-color: transparent;
}

.form-control:focus {
    border: 0;
    box-shadow: unset;
    border-bottom: 1px solid rgb(0, 184, 255);
}

.form-control:disabled {
    background-color: #d9efff;
}

.form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--primary-color);
}

.table > :not(caption) > * > * {
    background-color: transparent;
}

.island {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 4px 3px;
}

.padding10 {
    padding: 10px;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgba(38, 63, 73, 0.8);
    --bs-btn-hover-border-color: var(--primary-color);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-color);
    --bs-btn-active-border-color: var(--primary-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary-color);
    --bs-btn-disabled-border-color: var(--primary-color);
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }
}
