.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.pageWrap {
    display: flex;
    gap: 20px;
    height: 100%;
}

.leftSide {
    padding: 10px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.rightSide {
    padding: 10px;
    flex-grow: 1;
}

.rightSidePreview {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 20px; /* Adjust the gap according to your design preferences */
    height: min-content;
}

.rightSidePreviewItem {
    padding: 10px;
    width: 100%;
    min-width: 320px;
}

.rightSidePreviewItemInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    font-weight: bold;
    text-align: center;
}

.email {
    width: 100%;
    text-align: center;
}

.about {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}

.aboutTitle {
    color: var(--theme-primary);
    font-weight: bold;
}

.aboutCntent{
    font-size: small;
}

@media (max-width: 768px) {
    .pageWrap {
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column;
    }

    .leftSide {
        width: 100%;
    }
}
