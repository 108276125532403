.pageWrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 500px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 350px;
    gap: 10px;
}

.title {
    background-color: #263f49;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    padding: 10px;
}
