.selected {
    background-color: lightBlue;
}

.row {
    cursor: pointer;
}

.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.paginationInfo {
    width: max-content;
    display: flex;
    gap: 5px;
}

.pagination button {
    background-color: lightblue;
    border: 0;
    border-radius: 50%;
    padding: 10px;
    min-height: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
}

.footer {
    width: 100%;
    height: 50px;
    display: flex;
    padding-top: 15px;
    padding-left: 15px;
}
