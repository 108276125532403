@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

.pageWrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 50px;
}

.wrapper {
    overflow: hidden;
    max-width: 390px;
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}
.wrapper .title-text {
    display: flex;
    width: 200%;
}
.wrapper .title {
    width: 50%;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .slide-controls {
    position: relative;
    display: flex;
    height: 50px;
    width: 100%;
    overflow: hidden;
    margin: 30px 0 10px 0;
    justify-content: space-between;
    border: 1px solid lightgrey;
    border-radius: 15px;
}
.slide-controls .slide {
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.6s ease;
}
.slide-controls label.signup {
    color: #000;
}
.slide-controls .slider-tab {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    z-index: 0;
    border-radius: 15px;
    background: -webkit-linear-gradient(left, #263f49, #263f49, #1e3e61, #263f49);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type='radio'] {
    display: none;
}
#signup:checked ~ .slider-tab {
    left: 50%;
}
#signup:checked ~ label.signup {
    color: #fff;
    cursor: default;
    user-select: none;
}
#signup:checked ~ label.login {
    color: #000;
}
#login:checked ~ label.signup {
    color: #000;
}
#login:checked ~ label.login {
    cursor: default;
    user-select: none;
}
.wrapper .form-container {
    width: 100%;
    overflow: hidden;
}
.form-container .form-inner {
    display: flex;
    width: 200%;
}
.form-container .form-inner form {
    width: 50%;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-inner form .field {
    height: 50px;
    width: 100%;
    margin-top: 20px;
}
.form-inner form .field input,
.form-inner form .field select {
    height: 100%;
    width: 100%;
    outline: none;
    padding-left: 15px;
    border-radius: 15px;
    border: 1px solid lightgrey;
    border-bottom-width: 2px;
    font-size: 17px;
    transition: all 0.3s ease;
}
.form-inner form .field input:focus {
    border-color: #1a75ff;
    /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder {
    color: #999;
    transition: all 0.3s ease;
}
form .field input:focus::placeholder {
    color: #1a75ff;
}
.form-inner form .pass-link {
    margin-top: 5px;
}
.form-inner form .signup-link {
    text-align: center;
    margin-top: 30px;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a {
    color: #1a75ff;
    text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
    text-decoration: underline;
}
form .btn {
    height: 50px;
    width: 100%;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
}
form .btn .btn-layer {
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    background: -webkit-linear-gradient(left, #263f49, #263f49, #263f49, #263f49);
    transition: all 0.4s ease;
}
form .btn:hover .btn-layer {
    left: 0;
}
form .btn button {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    background: none;
    border: none;
    color: #fff;
    padding-left: 0;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
