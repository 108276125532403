.modalWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 300px;
    max-height: 90vh;
    min-width: min(500px, 95vw);
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    overflow: auto;
}

.headerBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
