#header {
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    padding: 2.5px 0;
    background: var(--base-color);
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  #header.header-transparent {
    background: transparent;
  }
  
  #header.header-scrolled {
    background: rgba(38, 63, 73, 0.9);
    padding: 12px;
  }
  
  #header .logo {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  #header.header-scrolled .logo img {
    display: none;
  }

  #header.header-scrolled .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

  #header .logo h1 {
    font-size: 24px;
    margin: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    text-transform: none;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 80px;
  }

  #header .container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

  
  @media (max-width: 768px) {
    #header.header-scrolled {
      padding: 15px 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
    position: static;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    margin-left: 10px;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 10px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
    color: #2f4d5a;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #67b0d1;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .megamenu {
    position: static;
  }
  
  .navbar .megamenu ul {
    margin: 0;
    padding: 10px;
    display: block;
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }
  
  .navbar .megamenu ul li {
    flex: 1;
  }
  
  .navbar .megamenu ul li strong {
    padding: 10px 0 10px 20px;
    display: block;
  }
  
  .navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
    padding: 8px 10px;
  }
  
  .navbar .megamenu ul li a:hover,
  .navbar .megamenu ul li .active,
  .navbar .megamenu ul li .active:hover {
    color: #67b0d1;
  }
  
  .navbar .megamenu:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(43, 111, 142, 0.9);
    transition: 0.3s;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #2f4d5a;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #67b0d1;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #67b0d1;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
  
  .navbar-mobile .megamenu ul li a,
  .navbar-mobile .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
  }
  
  .navbar-mobile .megamenu ul li a:hover,
  .navbar-mobile .megamenu ul li .active,
  .navbar-mobile .megamenu ul li .active:hover {
    color: #67b0d1;
  }
  