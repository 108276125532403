#footer {
    background: #263f49;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-top: -90px;
    margin-bottom: 15px;
    background: white;
    color: #2f4d5a;
    border-top: 4px solid #67b0d1;
    text-align: center;
    padding: 30px 20px;
    position: sticky;
    z-index: 2;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #2f4d5a;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #67b0d1;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #b5d9e9;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #a2cfe3;
  }
  
  #footer .copyright {
    border-top: 1px solid #385b6b;
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  @media (max-width: 575px) {
    #footer .footer-top .footer-info {
      margin: -20px 0 30px 0;
    }
  }

  .images{
    display: flex;
    margin-top: 15px;
    justify-content: flex-start;
  }

  .techLogo{
    max-width:125px;
    max-height: 100px;
}

@media (max-width: 575px) {
 
  .images{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    justify-content: flex-start;
  }
   .techLogo{
    max-width:100px;
    max-height: 65px;
}
}
