@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@300&family=Open+Sans:wght@300&family=Poppins:wght@300&family=Roboto+Condensed:wght@300;400&display=swap');

.adminPagesWrap {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    font-family: 'Inter', sans-serif;
}

.adminContent {
    width: 100%;
    margin: 20px;
    max-height: calc(100vh - 40px);
    overflow: auto;
}

@media (max-width: 768px) {
    .adminContent {
        margin: 0;
        border-radius: 0;
        padding-left: 10px;
        padding-right: 10px;
        /* background-color: rgba(255, 255, 255, 0.9); */
        max-height: 100vh;
    }
}
